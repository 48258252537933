<template>
  <ActionViewContainer
    :title-icon="getIconForOperationSettingsItem('actionsets')"
    :breadcrumbsItems="formattedBreadcrumbs"
    :actions="actions"
    :entryData="entryData"
    @add-actionset="openAddModal"
    title="attach_actionsets"
  >
    <template slot="table">
      <v-data-table
        :headers="headers"
        :items="nodeActionsets"
        :loading="loading"
        class="elevation-0"
        hide-default-footer
        disable-pagination
      >
        <template v-slot:item.aspect_key="{ item }">
          <span>{{ item.aspect_key }}</span><br>
          <span v-if="isInheritedActionset(item)">({{ $t('inherited') }})</span>
          <span v-else-if="item.recursive">({{ $t('recursive') }})</span>
        </template>

        <template v-slot:item.filters="{ item }">
          <template v-if="item.filter_key || item.relation_filter_key">
            <span v-if="item.filter_key">
              <span class="font-weight-bold">{{ $t('global') }}:</span> {{item.filter_key}}
            </span>
            <br>
            <span v-if="item.relation_filter_key">
              <span class="font-weight-bold">{{ $t('local') }}:</span> {{item.relation_filter_key}}
            </span>
          </template>

          <template v-else> - </template>
        </template>

        <template v-slot:item.actions="{ item }">

          <template v-if="! isInheritedActionset(item)">
            <v-icon
              class="mr-2"
              @click.stop="editActionset(item)"
            >
              mdi-pencil
            </v-icon>

            <v-icon
              class="mr-2"
              @click.stop="editActionsetFilter(item)"
            >
              mdi-filter-plus
            </v-icon>

            <v-icon
              class="mr-2"
              @click.stop="removeActionset(item)"
            >
              mdi-minus-circle
            </v-icon>
          </template>

          <template v-else> - </template>

        </template>
        
      </v-data-table>

      <DefaultModal
        :title="$t('Add actionset')"
        ref="modal"
        @submit="saveActionset"
        :loading="loading"
      >
        <template v-slot:content>
          <v-select
            v-model="selectedActionsetId"
            :items="allActionsets"
            item-text="aspect_key"
            item-value="actionset_id"
            :label="$t('Actionsets')"
            :readonly="editing"
            outlined
          ></v-select>

          <v-switch
            :label="$t('Recursive')"
            v-model="recursive"
          >
          </v-switch>
        </template>
      </DefaultModal>

      <FilterAssocModal 
        @submit-filter="saveActionsetFilter"
        ref="filter_assoc_modal"
      />

    </template>

  </ActionViewContainer>
</template>

<style scoped>
  >>>.word-break {
    word-break: break-word;
  }
</style>

<script>

import { mapActions, mapGetters, mapState } from 'vuex'

import ActionViewContainer from '@/components/content/ActionViewContainer'

import DefaultModal from '@/components/DefaultModal'
import FilterAssocModal from '@/components/node/FilterAssocModal'

import AppActions from '@/store/app/actions-types'
import EntryActions from '@/store/content/entry/actions-types'
import ActionsetActions from '@/store/operation/actionset/actions-types'

export default {
  name: "AttachActionsets",

  components: {
    ActionViewContainer,
    DefaultModal,
    FilterAssocModal,
  },

  data() {
    return {
      loading: false,
      actions: [
        {
          text: "Add Actionset",
          eventToEmit: "add-actionset"
        }
      ],
      entryData: {},
      headers: [
        {text: 'Key', value: 'aspect_key'},
        {text: 'Actions', value: 'actions_key', cellClass: 'word-break'},
        {text: 'Filters', value: 'filters'},
        {text: 'Actions', value: 'actions', align: 'center', sortable: false}
      ],
      nodeActionsets: [],
      allActionsets: [],
      selectedActionsetId: null,
      recursive: null,
      editing: false
    }
  },

  computed: {
    ...mapGetters('app', [
      'getIconForOperationSettingsItem',
      'getApplicationInstanceById'
    ]),

    ...mapState({
      userRoles: state => state.core.auth.userRoles,
      breadcrumb: state => state.content.entry.breadcrumb,
      entries: state => state.content.entry.entries,
    }),

    formattedBreadcrumbs() {
      let formatted = [
        {
          prependIcon: 'mdi-home-outline',
          iconColor: 'primary',
          to: {
            name: 'ContentHome',
            params: { appId: this.$route.params.appId }
          },
          link: true,
          disabled: false,
          ripple: true
        },
      ]

      const reversed = [].concat(this.breadcrumb).reverse()

      reversed.forEach((element, index, array) => {
        formatted.push({
          to: {
            name: 'ContentNavigation',
            params: { parentId: element.node_id }
          },
          text: element.name,
          ripple: true,
          disabled: Object.is(array.length - 1, index),
        })
      })

      return formatted
    },
  },

  mounted() {
    this.loadData()
  },

  methods: {
    ...mapActions('app', [
      AppActions.OPEN_APP_SUCCESS_MESSAGE
    ]),

    ...mapActions('content/entry', [
      EntryActions.LIST_SUBFOLDER,
      EntryActions.GET_ENTRY_DATA,
    ]),

    ...mapActions('operation/actionset', [
      ActionsetActions.GET_ACTIONSETS_BY_NODE,
      ActionsetActions.GET_ALL_ACTIONSETS,
      ActionsetActions.ADD_ACTIONSET_NODE_RELATIONSHIP,
      ActionsetActions.REMOVE_ACTIONSET_NODE_RELATIONSHIP
    ]),

    loadData() {
      this.loading = true

      let nodeId = this.$route.params.nodeId

      this[EntryActions.LIST_SUBFOLDER]({parentId: nodeId})
        .then(() => {
          this.loading = false
        })
        .catch(() => this.loading = false)

      this[EntryActions.GET_ENTRY_DATA](nodeId)
        .then((data) => {
          this.entryData = data
          this.loading = false
        })
        .catch(() => this.loading = false)

      this[ActionsetActions.GET_ACTIONSETS_BY_NODE](nodeId)
        .then((data) => {
          this.nodeActionsets = data.items
          this.loading = false
        })
        .catch(() => this.loading = false)
    },

    openAddModal() {
      this.editing = false
      this.recursive = true
      this.openModal()
    },

    openModal() {
      this.loading = true
      this[ActionsetActions.GET_ALL_ACTIONSETS]()
        .then((data) => {
          this.allActionsets = data.items
          this.loading = false
        })
        .catch(() => this.loading = false)
      this.$refs.modal.openModal()
    },

    saveActionset() {
      let params = {
        nodeId: this.$route.params.nodeId,
        actionsetId: this.selectedActionsetId,
        data: {
          recursive: this.recursive == true,
          item_order: 1,
          filter_id: null
        }
      }

      this[ActionsetActions.ADD_ACTIONSET_NODE_RELATIONSHIP](params)
        .then(() => {
          this.$refs.modal.submitting = false
          this.$refs.modal.closeModal()
          this.editing = false
          this.loadData()
        })
        .catch(() => {
          this.$refs.modal.submitting = false
          this.editing = false
        })
    },

    editActionset(item) {
      this.editing = true
      this.selectedActionsetId = item.actionset_id
      this.recursive = item.recursive

      this.openModal()
    },

    editActionsetFilter(item) {
      this.selectedActionsetId = item.actionset_id
      this.recursive = item.recursive

      this.$refs.filter_assoc_modal.tenantIdFilter = item.tenant_id

      this.$refs.filter_assoc_modal.open(item.relation_filter_id)
    },

    saveActionsetFilter(form) {
      const filterId = parseInt(form.get('filter')) || null

      let params = {
        nodeId: this.$route.params.nodeId,
        actionsetId: this.selectedActionsetId,
        data: {
          recursive: this.recursive == true,
          item_order: 1,
          filter_id: filterId
        }
      }

      this[ActionsetActions.ADD_ACTIONSET_NODE_RELATIONSHIP](params)
        .then(() => {
          this.$refs.filter_assoc_modal.loading = false

          this[AppActions.OPEN_APP_SUCCESS_MESSAGE](this.$t('filter_updated'))

          this.$refs.filter_assoc_modal.close()

          this.loadData()
        })
        .catch(() => {
          this.$refs.filter_assoc_modal.loading = false
        })
    },

    removeActionset(item) {
      this.loading = true

      let params = {
        nodeId: this.$route.params.nodeId,
        actionsetId: item.actionset_id
      }

      this[ActionsetActions.REMOVE_ACTIONSET_NODE_RELATIONSHIP](params)
        .then(() => {
          this.loadData()
        })
        .catch(() => {
          this.loading = false
        })
    },

    isInheritedActionset(actionset) {
      return actionset.relation_node_id != this.$route.params.nodeId
    },
  }
}

</script>
